import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

/**
 * ElectronInstallationGuard
 */
@Injectable()
export class ElectronInstallationGuard implements CanActivate {
  public onElectronAlreadyInstalled: any;

  constructor(private injector: Injector) { }

  public canActivate(route: ActivatedRouteSnapshot) {
    this.onElectronAlreadyInstalled = route.data.electron.onElectronAlreadyInstalled;
    if (localStorage.getItem('electronInstalled') !== 'true') {
      return of(true);
    } else {
      this.handleAlreadyInstalled(false);
      return of(false);
    }
  }

  protected handleAlreadyInstalled(canActivate: boolean): void {
    if (!canActivate && this.onElectronAlreadyInstalled && this.onElectronAlreadyInstalled.do) {
      const args = this.onElectronAlreadyInstalled.deps.map((token: any) => this.injector.get<any>(token));
      this.onElectronAlreadyInstalled.do.apply(null, args);
    }
  }
}
