import * as pkg from '../../package.json';
import { versions } from './versions';

export const environment = {
  production: true,
  name: pkg.name,
  version: pkg.version,
  commit: versions.commit,
  branch: versions.branch,
};
