import { DisableBackBrowserGuard } from './shared/guard/disableBackBrowser.guard';
import { BrowserGuard } from '@amwellnow/browser';
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, Routes } from '@angular/router';
import { ElectronInstallationGuard, ElectronState } from './download-app';
import { TokenResolver } from './resolvers/token.resolver';
import { StartVisitGuard } from './shared/guard/start-visit-guard';
import { UNSUPPORTED_BROWSER_TYPE } from './unsupported-browser/unsupported-browser-type.enum';
import { CompositeGuard } from './_helpers';
import { RouteName } from './shared/model/route-name.enum';
import { PreloadStrategy } from './utilities/preloading.service';
import { Auth0Guard } from '@app/shared/guard/auth0.guard';
import { ErrorModalComponent } from '@app/shared/components/error-modal/error-modal.component';

const redirectToMeetingUrl = (router: Router, visitUrl, sameRouteInfo: ActivatedRouteSnapshot) => {
  if (!visitUrl?.length) {
    console.error('Missing visitUrl');
    return router.navigate(['/start']);
  }

  if (sameRouteInfo) {
    // to prevent flicker, do not set window.location.href, if we are to keep user to the same route
    // setting browserChecksPassed to true ensures that we prevent recursive calls on same route
    return router.navigate([sameRouteInfo.routeConfig?.path], {
      queryParams: {
        ...sameRouteInfo.queryParams,
        browserChecksPassed: true
      }
    });
  }

  return window.location.href = visitUrl;
};

export const routes: Routes = [
  {
    path: RouteName.VisitStarted,
    loadChildren: () => import('./electron-started/electron-started.module').then((m) => m.ElectronStartedModule),
    resolve: {
      state: ElectronState
    },
    data: {
      layoutType: 'responsive',
      pageTitleKey: 'pageTitleVisitStarted',
      footer: true
    }
  },
  {
    path: RouteName.LaunchApp,
    loadChildren: () => import('./launch-electron/launch-electron.module').then((m) => m.LaunchElectronModule),
    canActivate: [BrowserGuard],
    resolve: {
      state: ElectronState
    },
    data: {
      layoutType: 'responsive',
      footer: true,
      browser: {
        supported: ['ie'],
        onUnsupported: {
          do: (router: Router, stateResolver: ElectronState) => {
            stateResolver.resolve().subscribe((electronState: any) => {
              return redirectToMeetingUrl(router, electronState.visitUrl, electronState.sameRouteInfo);
            });
          },
          deps: [Router, ElectronState]
        }
      }
    }
  },
  {
    path: RouteName.DownloadApp,
    loadChildren: () => import('./download-app/download-app.module').then((m) => m.DownloadAppModule),
    canActivate: [CompositeGuard],
    resolve: {
      state: ElectronState,
      resolvedToken: TokenResolver
    },
    data: {
      pageTitleKey: 'pageTitleThankYou',
      footer: true,
      layoutType: 'responsive',
      guards: [BrowserGuard, ElectronInstallationGuard],
      electron: {
        // if electron has already been installed, then
        // we direct users away from this page.
        onElectronAlreadyInstalled: {
          do: (router: Router, stateResolver: ElectronState) => {
            stateResolver.resolve().subscribe((state) => {
              router.navigate(['/launch-app'], { state });
            });
          },
          deps: [Router, ElectronState]
        }
      },
      browser: {
        supported: ['ie'],
        onUnsupported: {
          do: (router: Router, stateResolver: ElectronState) => {
            stateResolver.resolve().subscribe((electronState: any) => {
              return redirectToMeetingUrl(router, electronState.visitUrl, electronState.sameRouteInfo);
            });
          },
          deps: [Router, ElectronState]
        },
        onChromeOnIOS: {
          do: (router: Router, stateResolver: ElectronState) => {
            stateResolver.resolve().subscribe(state => {
              return router.navigate(['unsupported-browser'], {
                queryParams: {
                  requestedUrl: state.visitUrl,
                  unsupportedBrowserType: UNSUPPORTED_BROWSER_TYPE.CHROME_ON_IOS,
                  recommendedBrowser: 'Safari'
                }
              });
            });
          },
          deps: [Router, ElectronState]
        },
        onEdgeOnIOS: {
          do: (router: Router, stateResolver: ElectronState) => {
            stateResolver.resolve().subscribe(state => {
              return router.navigate(['unsupported-browser'], {
                queryParams: {
                  requestedUrl: state.visitUrl,
                  unsupportedBrowserType: UNSUPPORTED_BROWSER_TYPE.EDGE_ON_IOS,
                  recommendedBrowser: 'Safari'
                }
              });
            });
          },
          deps: [Router, ElectronState]
        },
      }
    }
  },
  {
    path: RouteName.Start,
    loadChildren: () => import('./start/start.module').then(m => m.StartModule)
  },
  {
    path: RouteName.TermsOfUse,
    loadChildren: () => import('./tou/tou.module').then((m) => m.TouModule),
    data: {
      layoutType: 'responsive'
    }
  },
  {
    path: RouteName.EndCall,
    loadChildren: () => import('./end-call/end-call.module').then((m) => m.EndCallModule),
    data: { pageTitleKey: 'pageTitleThankYou' }
  },
  {
    path: RouteName.Rating,
    loadChildren: () => import('./rating/rating.module').then((m) => m.RatingModule),
    data: {
      layoutType: 'responsive',
      pageTitleKey: 'pageTitleRatings'
    }
  },
  {
    path: RouteName.VisitEnded,
    loadChildren: () => import('./no-visit/no-visit.module').then((m) => m.NoVisitModule),
    data: { layoutType: 'responsive', pageTitleKey: 'pageTitleThankYou', footer: true, fullScreen: false }
  },
  {
    path: RouteName.ThankYou,
    loadChildren: () => import('./thank-you/thank-you.module').then((m) => m.ThankYouModule),
    canDeactivate: [DisableBackBrowserGuard],
    data: {
      layoutType: 'responsive',
      pageTitleKey: 'pageTitleThankYou'
    }
  },
  {
    path: RouteName.RatingDislike,
    loadChildren: () => import('./dislike-rating/rating-dislike.module').then((m) => m.RatingDislikeModule),
    canDeactivate: [DisableBackBrowserGuard],
    data: {
      layoutType: 'responsive',
      pageTitleKey: 'pageTitleRatings'
    }
  },
  {
    path: RouteName.Error,
    component: ErrorModalComponent,
    data: {
      branding: true,
      footer: true,
      layoutType: 'responsive'
    }
  },
  {
    path: RouteName.ExpiredLink,
    loadChildren: () => import('./expired-link/expired-link.module').then((m) => m.ExpiredLinkModule),
    data: {
      branding: true,
      footer: true,
      layoutType: 'responsive'
    }
  },
  {
    path: RouteName.UnsupportedBrowser,
    loadChildren: () => import('./unsupported-browser/unsupported-browser.module').then((m) => m.UnsupportedBrowserModule),
    data: {
      layoutType: 'responsive'
    }
  },
  {
    path: RouteName.CallStart,
    canActivate: [StartVisitGuard],
    resolve: { resolvedToken: TokenResolver },
    loadChildren: () => import('./call-start/call-start.module').then(m => m.CallStartModule),
  },
  {
    path: RouteName.TechCheck,
    canActivate: [StartVisitGuard],
    loadChildren: () => import('./tech-check/tech-check-page.module').then(m => m.TechCheckPageModule),
    data: {
      footer: true,
      pageTitleKey: 'pageTitleTechCheck',
      layoutType: 'responsive'
    }
  },
  // TODO: Remove this once all micro-services configs have been updated.
  {
    path: RouteName.CallIntake,
    canActivate: [StartVisitGuard],
    resolve: { resolvedToken: TokenResolver },
    loadChildren: () => import('./call-start/call-start.module').then(m => m.CallStartModule),
  },
  {
    path: RouteName.GuestIntake,
    canActivate: [StartVisitGuard],
    resolve: { resolvedToken: TokenResolver },
    loadChildren: () => import('./patient/intake/intake.module').then(m => m.IntakeModule),
    data: {
      layoutType: 'responsive',
      pageTitleKey: 'pageTitlePatientInformation',
      footer: true,
      feedback: true
    }
  },
  {
    path: RouteName.CallConference,
    loadChildren: () => import('./call-conference/call-conference.module').then(m => m.CallConferenceModule),
    canActivate: [Auth0Guard],
    data: {
      fullScreen: true
    }
  },
  {
    path: RouteName.PatientIntake,
    loadChildren: () => import('./patient/intake/intake.module').then(m => m.IntakeModule),
    data: {
      layoutType: 'responsive',
      pageTitleKey: 'pageTitlePatientInformation',
      footer: true,
      feedback: true
    }
  },
  {
    path: RouteName.PatientSpinner,
    loadChildren: () => import('./patient/spinner/spinner.module').then(m => m.PatientSpinnerModule)
  },
  {
    path: RouteName.HomeTv,
    loadChildren: () => import('./home-tv/home-tv.module').then(m => m.HomeTvModule),
    data: {
      fullScreen: true
    },
    canActivate: [Auth0Guard],
  },
  {
    path: RouteName.ProviderLanding,
    loadChildren: () => import('./provider-landing-page/provider-landing-page.module').then(m => m.ProviderLandingPageModule),
    canActivate: [Auth0Guard],
    data: {
      layoutType: 'responsive',
      footer: true,
      branding: true
    }
  },
  {
    path: RouteName.Support,
    resolve: { resolvedToken: TokenResolver },
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    data: {
      pageTitleKey: 'pageTitleSupport',
      layoutType: 'responsive'
    }
  },
  {
    path: RouteName.PractitionerSpinner,
    loadChildren: () => import('./practitioner/spinner/spinner.module').then(m => m.PractitionerSpinnerModule),
  },
  {
    path: RouteName.CarepointCall,
    loadChildren: () => import('./carepoint-call/carepoint-call.module').then(m => m.CarepointCallModule),
    canActivate: [Auth0Guard],
    data: { fullScreen: true }
  },
  { path: RouteName.Auth, children: [], canActivate: [Auth0Guard] },
  { path: RouteName.Wildcard, redirectTo: '/start', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadStrategy})],
  providers: [ElectronState, ElectronInstallationGuard, BrowserGuard, CompositeGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
