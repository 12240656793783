import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class NoAnswerDialogOverlayContainer extends OverlayContainer {
  protected _createContainer(): void {
    const container: HTMLDivElement = document.createElement('div');
    const element: Element | null = document.querySelector('[data-aw-id="vcc"]');
    if (element !== null) {
      element.appendChild(container);
      this._containerElement = container;
    }
  }
}
