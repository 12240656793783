<div
  *awLayoutContent="'titleImage'"
  class="circle-graphics border s-icon primary-2-text"
  data-aw-id="error-icon"
  alt="">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <g fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="scale(1.65,1.7)">
                <path
                  d="M21.956.01c-4.292.186-8.039 2.849-9.667 6.733l-.075.189-.681.001C5.716 6.933 1 11.65 1 17.467 1 23.284 5.716 28 11.533 28H34.2c4.86 0 8.8-3.94 8.8-8.8l-.01-.426c-.223-4.662-4.073-8.374-8.79-8.374l-.784-.002-.016-.278C32.952 4.456 28.214 0 22.435 0l-.48.01zm-7.297 31.237c.369.324.443.859.2 1.266l-.106.146-3.5 4c-.364.415-.996.457-1.412.094-.369-.324-.443-.859-.2-1.266l.106-.146 3.5-4c.364-.415.996-.457 1.412-.094zm8 0c.369.324.443.859.2 1.266l-.106.146-3.5 4c-.364.415-.996.457-1.412.094-.369-.324-.443-.859-.2-1.266l.106-.146 3.5-4c.364-.415.996-.457 1.412-.094zm8 0c.369.324.443.859.2 1.266l-.106.146-3.5 4c-.364.415-.996.457-1.412.094-.369-.324-.443-.859-.2-1.266l.106-.146 3.5-4c.364-.415.996-.457 1.412-.094zM22.435 2c4.97 0 9 4.03 9 9l-.007.36-.041 1.04H34.2c3.756 0 6.8 3.044 6.8 6.8S37.956 26 34.2 26H11.533C6.821 26 3 22.18 3 17.467c0-4.713 3.82-8.534 8.533-8.534h2.11l.223-.694C15.053 4.551 18.498 2 22.435 2zM22 18c-2.164 0-4.115.993-4.895 2.553-.246.494-.046 1.095.448 1.342.494.246 1.095.046 1.342-.448C19.303 20.629 20.54 20 22 20s2.697.63 3.105 1.447c.247.494.848.694 1.342.448.494-.247.694-.848.448-1.342C26.115 18.993 24.164 18 22 18zm5.5-5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm-11 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
<ng-container
  data-aw-id="error-title"
  *awLayoutContent="'header'">
    {{ 'something_went_wrong' | i18next }}
</ng-container>
<div
  data-aw-id="error-msg">
    {{  matDialogData?.message || ('intake_error' | i18next) }}
</div>
