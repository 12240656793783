import { CanDeactivate } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DisableBackBrowserGuard implements CanDeactivate<any> {
  backClicked: boolean;
  constructor(public location: LocationStrategy) {
    this.location.onPopState(() => {
      this.backClicked = true;
      return false;
    });
  }

  canDeactivate(component: any) {
    // will prevent user from going back
    if (this.backClicked || this.backClicked === undefined) {
      this.backClicked = false;
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}
