import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApmService } from '@app/apm-module';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.less']
})
export class ErrorModalComponent {

  constructor(
    private apm: ApmService,
    @Inject(MAT_DIALOG_DATA) public readonly matDialogData: {message: string},
  ) {
    const transaction = this.apm.apmBase.getCurrentTransaction();
    transaction?.mark('showed_error_modal');
  }

}
