import { Injectable, Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { from, isObservable, of } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, every, map } from 'rxjs/operators';

export * from './i18Next/i18next.service';

export function isElectron() {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    return true;
  }
  return false;
}

const defaultToTrue = defaultIfEmpty(true);
const checkAllTrue = every(bool => !!bool);
const errorToFalse = catchError(e => of(false));
const coerceToStream = maybeStream => isObservable(maybeStream) ? from(maybeStream) : of(!!maybeStream);

@Injectable()
export class CompositeGuard implements CanActivate {
  constructor(private injector: Injector) { }

  public canActivate(route: ActivatedRouteSnapshot, state) {
    const guards = (route.data?.guards || []) as Array<Type<CanActivate>>;

    return from(guards).pipe(
      map(klass => this.injector.get(klass)),
      concatMap(activator => {
        return coerceToStream(activator.canActivate(route, state));
      }),
      checkAllTrue,
      errorToFalse,
      defaultToTrue
    );
  }
}
