import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TokenResolverService } from '@app/shared/service/token-resolver.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class TokenResolver implements Resolve<any> {
  tokens = {};
  constructor(private tokenResolverService: TokenResolverService) { }
  resolve(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.tokenResolverService.getToken(route.queryParams);
  }
}
