import { Injectable, InjectionToken, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Browser, BrowserInfo, detect } from 'detect-browser';
import { of } from 'rxjs';

type BrowserGuardConfig = {
  supported?: Array<Browser>;
  onUnsupported?: {
    do?: (...args: Array<unknown>) => {},
    deps?: Array<InjectionToken<unknown>>
  };
  onSupported?: {
    do?: (...args: Array<unknown>) => {},
    deps?: Array<InjectionToken<unknown>>
  };
  onChromeOnIOS?: {
    do?: (...args: Array<unknown>) => {},
    deps?: Array<InjectionToken<unknown>>
  };
  onEdgeOnIOS?: {
    do?: (...args: Array<unknown>) => {},
    deps?: Array<InjectionToken<unknown>>
  };
};

interface BrowserGuardSnapshot {
  data?: {
    browser?: BrowserGuardConfig;
  };
}

/**
 * BrowserGuard
 */
@Injectable({ providedIn: 'root' })
export class BrowserGuard implements CanActivate {
  constructor(private injector: Injector) { }

  canActivate(route: BrowserGuardSnapshot) {
    const browser = detect() as BrowserInfo;
    if (!route.data || !browser || !route.data.browser || !route.data.browser.supported) {
      return of(true);
    }

    const chromeOnIOS = this.isChromeOnIOS(browser);
    const edgeOnIOS = this.isEdgeOnIOS(browser);
    if (chromeOnIOS) {
      route.data.browser.onChromeOnIOS?.do?.apply(null, route.data.browser.onChromeOnIOS.deps.map(token => this.injector.get(token)));
      return of(true);
    }
    if (edgeOnIOS) {
      route.data.browser.onEdgeOnIOS?.do?.apply(null, route.data.browser.onEdgeOnIOS.deps.map(token => this.injector.get(token)));
      return of(true);
    }
    let result = false;
    if (route?.data?.browser?.supported?.length) {
      // tslint:disable-next-line
      for (let index = 0; index < route.data.browser.supported.length; index++) {
        const browserToCheck = route.data.browser.supported[index];
        if (browserToCheck === browser.name) {
          result = true;
          console.log('browser is unsupported and user will be prompted to use electron');
          break;
        }
      }
    }

    if (!result) {
      route.data.browser.onUnsupported?.do?.apply(null, route.data.browser.onUnsupported.deps.map(token => this.injector.get(token)));
    } else {
      route.data.browser.onSupported?.do?.apply(null, route.data.browser.onSupported.deps.map(token => this.injector.get(token)));
    }

    return of(result);
  }

  isChromeOnIOS(browser) {
    const browserName = browser?.name?.toLowerCase() || '';
    const os = browser?.os?.toLowerCase() || '';
    return (browserName === 'chrome' || browserName === 'crios') && os === 'ios'
      ? true
      : false;
  }

  isEdgeOnIOS(browser) {
    const browserName = browser?.name?.toLowerCase() || '';
    const os = browser?.os?.toLowerCase() || '';
    return (browserName === 'edge' || browserName === 'edge-ios' || browserName === 'edge-chromium') && os === 'ios'
      ? true
      : false;
  }
}
