import { SIX_HOURS } from '@app/shared/constants/common';

export function getCacheTime(): number {
  return Date.now() + SIX_HOURS;
}

export function getParsedLocalStorageItemWithTtl<T>(item: string): T | null {
  try {
    const parsedObj = JSON.parse(item);
    const { val, ttl } = parsedObj;

    if (ttl && ttl < Date.now()) {
      return null;
    }

    return val;
  } catch (e) {
    return null;
  }
}
