import { Router } from '@angular/router';
import { Inject, Injectable, NgZone, ErrorHandler } from '@angular/core';
import { AgentConfigOptions } from '@elastic/apm-rum';
import { testStorage } from '@aw/video-util';
import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { apm, ApmBase } from '@elastic/apm-rum';

export const APM = new InjectionToken<ApmBase>('APM Base Client');

function keys(store) {
  const numKeys = store.length;
  const ks = [];
  for (let i = 0; i < numKeys; i++) {
    ks.push(store.key(i));
  }
  return ks;
}

@Injectable()
export class ApmErrorHandler extends ErrorHandler {
  constructor(@Inject(APM) public apmBase: ApmBase) {
    super();
  }

  handleError(error) {
    this.apmBase.captureError(error.originalError || error);
    super.handleError(error);
  }
}

function safeParse(stuff) {
  try {
    return JSON.parse(stuff);
  } catch (e) {
    return {};
  }
}

function slurp(list, object) {
  return list.reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {});
}

@Injectable({
  providedIn: 'root'
})
export class ApmService {
  constructor(
    @Inject(APM) public apmBase: ApmBase,
    private router: Router,
    private readonly ngZone: NgZone
  ) {}

  init(config: AgentConfigOptions) {
    const apmInstance = this.ngZone.runOutsideAngular(() => this.apmBase.init(config));

    function addMetadata(transaction) {
      const maybeTokenPayload = sessionStorage.getItem('aw-token-payload') || '{}';
      const parsedTokenPayload = safeParse(maybeTokenPayload);
      const awSessionId = sessionStorage.getItem('aw-session-id');
      const localStorageKeys = keys(localStorage);
      const sessionStorageKeys = keys(sessionStorage);
      if (awSessionId) {
        transaction.addLabels({ aw_session_id: awSessionId });
        /** make sure this is also appended to errors, yo */
        apmInstance.addLabels({ aw_session_id: awSessionId });
      }

      const stuffICareAbout = slurp([
        'roomSourceId',
        'role',
        'tenantKey',
        'ehrId',
        'ehrType',
        'launchId',
        'encounterId'
      ], parsedTokenPayload);

      transaction.addLabels(stuffICareAbout);
      transaction.addLabels({
        session_storage_keys: sessionStorageKeys,
        local_storage_keys: localStorageKeys,
      });
      apmInstance.setCustomContext(stuffICareAbout);
      apmInstance.setCustomContext({ storage_enabled: testStorage() });
    }

    /** it is possible that transactions don't end well, so do this at start to be safe */
    this.apmBase.observe('transaction:start', addMetadata);
    /** it is possible not all metadata is available when a transaction starts, so do it at the end to be EXTRA SUPER SAFE */
    this.apmBase.observe('transaction:end', addMetadata);

    if (!apmInstance.isActive()) {
      return apmInstance;
    }

    /**
     * Start listening to route change once we
     * intiailize to set the correct transaction names
     */
    // this.observe()
    return apmInstance;
  }

  observe() {}
}

@NgModule({
  imports: [RouterModule],
  providers: [{ provide: APM, useValue: apm }]
})
export class ApmModule {}
